// Provides smooth scrolling to anchor links (e.g. on template-products.php, while establishing appropriate focus on tab-able elements for a fully accessible experience.

jQuery(function($){

  // Select all links with hashes
  $('a[href*="#"]')
  // Remove links that don't link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {

    // On-page links
    if (
      location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')
      &&
      location.hostname === this.hostname
    ) {
      // Determine element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation will actually happen
        event.preventDefault();
        $('html, body').animate({
          // Account for fixed header and adjust target
          scrollTop: target.offset().top - ($('#masthead').height() - 1)
        }, 1000, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(':focus')) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          }
        });
      }
    }
  });

});
